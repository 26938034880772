import React from 'react'
import { LazyPlotly as LazyPlotlyComponent } from './Plotly.js'
import { LazyHighcharts as LazyHighchartsComponent } from './Highcharts.js'
import { LazyChartjs as LazyChartjsComponent } from './Chartjs.js'
import chroma from 'chroma-js'
import deepmerge from 'deepmerge/'
import worldMap from "@highcharts/map-collection/custom/world.topo.json";
import thMap from "@highcharts/map-collection/countries/th/th-all.topo.json";

import styles from '../../styles/_variables.module.scss'

export const defaultStyles = {
  colors: [
    styles.secondaryColor,
    styles.primaryColor,
    // '#1f77b4',  // muted blue
    // '#ff7f0e',  // safety orange
    '#2ca02c',  // cooked asparagus green
    '#7f7f7f',  // middle gray
    '#9467bd',  // muted purple
    '#17becf',  // blue-teal
    '#d62728',  // brick red
    '#8c564b',  // chestnut brown
    '#e377c2',  // raspberry yogurt pink
    '#bcbd22',  // curry yellow-green
  ],
  areaAlpha: 0.75,
  dimFactor: 0.4,
  font: {
    family: "Dindan",
    color: styles.textColor,
    size: 14,
  },
  title: {
    font: {
      size: 18,
    },
  },
  axisTitle: {
    font: {
      size: 16,
    },
  },
  tick: {
    font: {
      size: 14,
    },
  },
  tooltip: {
    font: {
      color: 'white',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  },
  paperColor: 'rgba(255, 255, 255, 0)',
  plotColor: 'rgba(255, 255, 255, 0)',
  gridlineColor: styles.gridlineColor,
  zerolineColor: styles.zerolineColor,
}

function convertSingleColor(c, multiplier=1) {
  return chroma(c).alpha(defaultStyles.areaAlpha * multiplier).hex()
}

export function getAreaColor(color, multiplier) {
  if (typeof color === "object")
    return color.map(x => convertSingleColor(x, multiplier))
  return convertSingleColor(color, multiplier)
}

export function LazyPlotly(props) {
  return(
    <LazyPlotlyComponent
      defaultStyles={defaultStyles}
      {...props}
    />
  )
}

export function LazyChartjs(props) {
  return(
    <LazyChartjsComponent
      defaultStyles={defaultStyles}
      {...props}
    />
  )
}

export function LazyHighcharts(props) {
  return(
    <LazyHighchartsComponent
      defaultStyles={defaultStyles}
      {...props}
    />
  )
}



export function SimpleChart({ type, data, start, interval, isDate, dateUnit, categories, xtitle, ytitle, showLegend, stacking, override, altImage }) {
  
  if (typeof start === "string") {
    start = Date.parse(start)
    isDate = true
  }

  const finalData = (typeof data[0] === "number")
    ? [{ name: "Value", data: data }]
    : data

  const providedOptions = {
    chart: {
      type: type || 'spline',
    },
    // stacking options
    plotOptions: ['line', 'spline', 'area', 'areaspline', 'column', 'bar'].reduce((a, v) => ({...a, [v]: { stacking: stacking }}), {
      series: {
        pointStart: start,
        pointIntervalUnit: dateUnit || (isDate && 'month'),
        pointInterval: interval,
      }
    }),
    series: finalData,
    xAxis: {
      categories: categories,
      type: isDate ? 'datetime' : 'linear',
      title: {
        text: xtitle,
      },
    },
    yAxis: {
      title: {
        text: ytitle,
      },
    },
    legend: {
      enabled: showLegend === undefined ? finalData.length > 1 : showLegend,
    },
  }

  return <LazyHighcharts
    altImage={altImage}
    options={deepmerge(providedOptions, override || {})}
  />

}

export function SimpleMap({ map, data, altImage, override }) {

  const providedOptions = {
    chart: {
      map: map === "th" ? thMap : worldMap,
      type: "map",
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },
  
    mapView: {
      projection: {
        name: "Miller",
      },
    },
    mapNavigation: {
      enabled: true,
      enableMouseWheelZoom: false,
      buttonOptions: {
        verticalAlign: 'bottom',
      },
    },
    colorAxis: {
      // type: 'logarithmic',
      stops: [
        [0, defaultStyles.colors[0]],
        [0.2, defaultStyles.colors[0]],
        [0.5, '#ffffca'],
        [0.8, defaultStyles.colors[1]],
        [1, defaultStyles.colors[1]],
      ],
    },
    series: [{
      data: data,
      name: 'ข้อมูล',
    }]
  }

  return (
    <LazyHighcharts
      altImage={altImage}
      constructorType={"mapChart"}
      options={deepmerge(providedOptions, override || {})}
    />
  )
}

export function SimplePie({ data, categories, altImage }) {
  
  return <LazyHighcharts
    altImage={altImage}
    options={{
      chart: {
        type: 'pie',
      },
      series: [{
        name: 'Value',
        data: data.map((v, i) => ({ name: categories[i], y: v })),
        size: '80%',
        innerSize: '60%',
      }],
      // legend: {
      //   enabled: showLegend === undefined ? finalData.length > 1 : showLegend,
      // },
    }}
  />
}