import React from "react"
import worldMap from "@highcharts/map-collection/custom/world.topo.json";
import { LazyHighcharts, defaultStyles } from "../../../../src/components/Plots"

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const rawData = {
  ad: [0, 58867, 7536, 0, 6563, 29503, 11441],
  ae: [165228, 315863061, 398861602, 24330112, 16894661, 314550883, 417634682],
  af: [0, 783237, 13623, 0, 149106, 123841, 3211574],
  ag: [0, 1200916, 93568, 17215, 3274, 2465, 0],
  ai: [0, 89907, 0, 0, 0, 44, 0],
  al: [0, 18282, 851, 91, 107786, 507, 111448],
  am: [15, 24735, 68379, 719721, 19700, 15, 351],
  ao: [3483795, 4673950, 30074854, 79777, 452213, 5589142, 8905],
  ar: [0, 566288701, 13423649, 184488, 4818821, 73171461, 476850],
  as: [17695, 9684650, 5066, 41, 154, 17696, 367497],
  at: [518, 7780336, 7600912, 2798, 396211, 355323860, 44518],
  au: [305503723, 2784322184, 741773643, 89009105, 205704042, 1012552390, 1951108514],
  aw: [0, 83384, 0, 0, 430, 0, 58956],
  az: [0, 396277, 4107, 287903, 5055, 45859, 181401],
  ba: [0, 7783919, 6293, 0, 0, 5652, 0],
  bb: [0, 2412041, 56874, 0, 4265, 55107, 152600],
  bd: [1263466917, 418806297, 23426416, 16983784, 8294035, 1418164847, 1409424770],
  be: [1617, 365487759, 43698322, 932588, 28009354, 277428944, 49865939],
  bf: [0, 15221392, 0, 0, 9322, 189251, 211117],
  bg: [1705, 4031702, 8630915, 285217, 2967930, 13185923, 11341],
  bh: [0, 18015861, 4324116, 9428675, 3701633, 10608722, 20777758],
  bi: [0, 1122860, 0, 0, 0, 130824, 0],
  bj: [0, 15516162, 4999, 0, 0, 49245, 296359],
  bl: [0, 108534, 143, 0, 0, 0, 0],
  bm: [300, 240622, 987, 71, 1362, 494, 39],
  bn: [31837813, 36323922, 3391727, 8244512, 882474, 35991278, 50236663],
  bo: [0, 176741, 12026, 175906, 160988, 472066, 20],
  bq: [0, 2, 0, 0, 0, 0, 0],
  br: [1709828, 515147328, 91968587, 1262264, 9467854, 274944295, 21040092],
  bs: [0, 201526, 59, 0, 0, 33320, 0],
  bt: [355, 305434, 64980, 246406, 97723, 45585, 38302],
  bw: [0, 865457, 0, 40, 6, 76320, 563],
  by: [0, 1847294, 127, 0, 43591, 20091, 0],
  bz: [0, 51588, 32437, 0, 1844, 6953, 0],
  ca: [16305, 647126760, 123425106, 5645910, 2913336, 54169206, 55416195],
  cd: [0, 3436025, 22117, 777210, 9231, 7893799, 180983],
  cf: [0, 135912, 0, 0, 3981, 97164, 0],
  cg: [600, 2060646, 11623, 854664, 14140, 11545252, 79186],
  ch: [6670, 48040699, 14337977, 1535446, 809611, 275334154, 99927],
  ci: [9726351, 126768213, 186359, 20429, 112149, 52049620, 824828],
  ck: [6028, 63733, 290, 216807, 15809, 6028, 0],
  cl: [52720321, 23541207, 3852829, 241142, 948377, 80063498, 2513850],
  cm: [814625, 8809446, 6309, 61772, 1002645, 7143627, 540027],
  cn: [234638909, 2243056681, 960504816, 306474455, 523071158, 11277726780, 20094662625],
  co: [84934, 40855177, 105777588, 1264463, 407898, 33394561, 5973351],
  cr: [0, 3024345, 29841, 939, 24213, 2848553, 61918],
  cu: [0, 2302850, 93801, 108935, 0, 71530, 235750],
  cv: [0, 842476, 0, 0, 0, 1225, 0],
  cw: [0, 97679, 1687, 43271, 1348, 76979, 0],
  cy: [0, 1114029, 5782, 175793, 37459, 183195, 3258],
  cz: [605, 53280756, 67704223, 44444, 1774780, 2762872, 23349],
  de: [20853, 820349266, 94298447, 67891434, 24183360, 249752846, 87204784],
  dj: [0, 12753376, 11762, 4358446, 873314, 9695577, 11246089],
  dk: [2096, 89888044, 22073168, 53303, 1713087, 14286936, 2248665],
  dm: [0, 11346969, 2795, 22573, 31679, 384918, 0],
  do: [0, 3661728, 3547, 207484, 138410, 6902436, 166289],
  dz: [0, 38552524, 527471, 1599365, 186555, 3245947, 3389287],
  ec: [62, 34298645, 174798, 37201, 5074869, 8903381, 70031820],
  ee: [18, 4907890, 108792, 742097, 715936, 19305, 10324],
  eg: [2685832, 312923986, 15255182, 12784258, 12122859, 546031758, 14773440],
  eh: [0, 187882, 0, 0, 3116, 0, 0],
  es: [200, 231574046, 24808181, 999399, 70534415, 708570057, 20827851],
  et: [80, 2374707, 1071072, 1048683, 2048623, 11488445, 51143913],
  fi: [30, 14490907, 1110368, 1336268, 7741244, 6962225, 310805],
  fj: [864760, 36763066, 1750563, 140370, 263798, 1117215, 10931592],
  fm: [640755, 84291, 2808, 0, 218, 640755, 108955],
  fr: [4033718, 159748398, 198014871, 7256154, 22138418, 46198744, 7815812],
  ga: [2288242, 11363462, 0, 0, 28276, 2298124, 189025],
  gb: [11791, 643093796, 147340660, 14440545, 58065370, 109078736, 224277223],
  gd: [0, 616519, 0, 0, 59, 1304, 0],
  ge: [0, 439413, 1706, 354286, 9787, 46120, 5312807],
  gh: [11367583, 7271695, 1309874, 560217, 148462, 44302495, 4086108],
  gi: [0, 29, 0, 0, 0, 0, 0],
  gl: [0, 547, 70, 0, 0, 0, 0],
  gm: [0, 78920, 241, 0, 204, 374567, 40000],
  gn: [1278993, 2010067, 25404, 113601, 527, 4308467, 921328],
  gq: [0, 32145, 7570, 634285, 7, 1, 2637],
  gr: [1, 11647418, 1829724, 386862, 643641, 6312463, 7520118],
  gt: [0, 2021995, 1851068, 442515, 530445, 7984452, 552910],
  gu: [1896, 1106225, 4478, 59799, 23785, 56208, 720267],
  gw: [0, 19597, 0, 0, 747, 114665, 0],
  gy: [0, 2228335, 3453, 1440953, 4315, 46570, 0],
  hk: [2994820, 1613886527, 275230116, 95380119, 54965287, 88204486, 1774130132],
  hn: [0, 2564971, 479756, 198572, 374368, 499600, 118226],
  hr: [0, 2186435, 391846, 23327, 516805, 834651, 10],
  ht: [6, 716553, 453486, 0, 14724, 1295626, 1457],
  hu: [108, 31834225, 1006702, 378327, 1038399, 7259540, 286764],
  id: [87045949, 3142929913, 634695840, 101876237, 117562348, 3407940985, 4187582664],
  ie: [55, 18779126, 7520113, 37154, 376103, 17977617, 12643],
  il: [3371, 73159082, 12344964, 2713016, 6523512, 124836229, 2239874],
  in: [7828467, 2522461497, 1480988140, 44543207, 260922969, 5294362475, 2182583412],
  io: [0, 33933, 0, 0, 0, 0, 0],
  iq: [1259, 9398478, 1687479, 1215398, 423337, 2680114, 3158065],
  ir: [47265, 34605878, 29190232, 16211618, 1926636, 24711755, 7518872],
  is: [0, 349848, 83726, 35187, 36220, 106917, 50],
  it: [12766, 609874120, 52641005, 50499, 35728558, 82659292, 290433],
  jm: [2280, 13057008, 58, 402725, 246834, 392273, 1910834],
  jo: [0, 2120568, 45034533, 339962, 185837, 5356384, 6950543],
  jp: [21562464, 6082390974, 5010387088, 417924256, 286573110, 2817171932, 2879109285],
  ke: [5734066, 64074001, 3690877, 4508548, 5806229, 16002867, 14144118],
  kg: [27656, 46036, 1783, 0, 0, 279970, 3121039],
  kh: [1421334015, 1294993746, 425469412, 274660677, 103725104, 2479797141, 27476469743],
  ki: [136494, 179151, 48276, 2352, 21784, 136728, 125070],
  km: [16757, 10924, 17090, 52605, 4443, 16757, 0],
  kn: [4752, 600283, 0, 230476, 0, 7014, 0],
  kp: [0, 286426, 6913, 182084, 85352, 8165678, 28270],
  kr: [259414, 535299984, 358301274, 24952418, 210390804, 2203787553, 5671013876],
  kw: [3059175, 69272803, 8086380, 5277810, 1476357, 4187322, 45749857],
  ky: [0, 352850, 59, 0, 1543, 0, 0],
  kz: [0, 1060542, 672803, 853172, 240895, 379149, 4624576],
  la: [492321128, 2644601675, 171445114, 294894862, 152663814, 1261875917, 17963405786],
  lb: [1, 4528986, 2416804, 255144, 217605, 1675091, 9120210],
  lc: [0, 1283929, 0, 5793, 1577, 1813, 264],
  lk: [89613549, 149023516, 4755609, 7894928, 8746000, 119926869, 289007420],
  lr: [0, 772162, 721, 101, 945390, 215767, 9572],
  ls: [0, 18343, 0, 28992, 0, 174273, 0],
  lt: [59, 7280964, 79795, 18465, 7292558, 36277144, 2331583],
  lu: [160, 417506, 381370, 51626, 13207861, 307, 1540],
  lv: [2, 3059508, 51365, 3505340, 128324, 1735777, 10139406],
  ly: [0, 3839782, 75950, 346405, 98915, 117573, 1514866],
  ma: [53847, 19863364, 1730123, 1945567, 9988396, 5329421, 6656571],
  md: [1, 41338, 597, 0, 14, 30922, 0],
  me: [0, 739309, 4, 0, 44775, 131932, 0],
  mg: [19612, 23724599, 50731, 2157500, 858418, 25579545, 0],
  mh: [1149271, 27651, 150, 0, 16808, 1163512, 42817134],
  mk: [0, 73702, 0, 0, 53691, 185, 0],
  ml: [387, 625159, 364035, 0, 14563, 262674, 272520],
  mm: [1418998395, 2154960971, 197202174, 328206991, 200463018, 2393808584, 10384486849],
  mn: [82919, 708625, 9188192, 175310, 148769, 128868, 5203217],
  mo: [6123, 3012843, 44612, 935219, 20747, 36761, 1339061],
  mp: [1366734, 331851, 3558, 1930, 3663, 1366939, 0],
  mr: [0, 146169, 196, 38100, 0, 5091941, 3300],
  ms: [0, 3137, 0, 0, 0, 0, 0],
  mt: [386, 262236, 84119, 0, 329373, 234882, 190467],
  mu: [3702, 32592887, 12441557, 4194984, 1850857, 419332, 691161],
  mv: [26098206, 49681264, 7242118, 15678958, 4323944, 26922358, 6967759],
  mw: [3780, 214064, 371, 0, 0, 6842370, 19430],
  mx: [2354, 341778125, 277886963, 8503485, 46923161, 75372478, 5055265],
  my: [89095576, 3470820301, 491227193, 95829190, 287724624, 2650556152, 26214154277],
  mz: [6602124, 13598934, 1178507, 24390, 248089, 12572894, 169007977],
  na: [0, 1372577, 16628, 0, 2261, 274077, 64964250],
  nc: [49157, 7705374, 44518, 7640610, 234657, 152676, 6825394],
  ne: [0, 153047, 92691, 0, 0, 946244, 0],
  ng: [40, 12007988, 75196499, 25586562, 541240, 88736263, 30489024],
  ni: [0, 1910484, 120380, 19568, 39529, 488687, 0],
  nl: [15552, 345640471, 82486075, 6156851, 17389929, 1362428283, 37867474],
  no: [11056, 307513721, 4608606, 6617620, 1700139, 51884821, 411016],
  np: [1745, 878557, 4106853, 1246960, 526830, 13904590, 205548],
  nr: [122405, 29385, 1565, 527, 0, 122405, 0],
  nz: [28822667, 382764216, 149918063, 25401877, 63298532, 89445215, 225487352],
  om: [50, 56404450, 1835044, 9137526, 2318646, 856846472, 57121872],
  pa: [1721, 13554809, 5875479, 47487, 61798, 679356, 2752761],
  pe: [0, 10211070, 1428735, 614532, 16370027, 22632818, 4763516],
  pf: [379016, 418591, 37599, 29097, 58701, 417825, 7496727],
  pg: [4895297, 105955205, 1110685, 300302, 3403918, 11973734, 3977040],
  ph: [242670944, 1712055707, 302999398, 109685366, 135266620, 1088364806, 5501105084],
  pk: [51700, 331856809, 22114039, 30090751, 165918586, 1231479650, 73229530],
  pl: [109, 135867735, 50542534, 4469540, 24844369, 104478538, 969454],
  ps: [0, 33, 0, 0, 0, 0, 0],
  pt: [0, 23201888, 714953, 473075, 485976, 8028550, 69004],
  pw: [0, 510842, 23345, 0, 0, 960, 41869],
  py: [0, 969164, 121146, 0, 72042, 2695329, 653334],
  qa: [28203, 93471432, 21817111, 6337401, 3712224, 2870167, 24472866],
  ro: [0, 29494038, 216133, 222015, 11357621, 2638391, 294409],
  rs: [0, 2010079, 6003, 0, 76014, 138172, 79],
  ru: [35721, 96457100, 11528247, 1979295, 8140632, 80052805, 223131058],
  rw: [0, 226485, 2057, 3500, 105725, 3528, 0],
  sa: [12703, 206377764, 43949669, 51280549, 14099646, 233798586, 401523226],
  sb: [1047815, 35087468, 567434, 0, 0, 1088918, 791176],
  sc: [297, 6613587, 172785, 344300, 55739, 25000, 2949197],
  sd: [0, 4297025, 82848, 201207, 218393, 2836923, 32088],
  se: [1440, 41830745, 4503678, 33311202, 3968217, 5053268, 44369],
  sg: [8388425, 2064692082, 136389190, 27154688, 22272045, 1045219500, 42494051615],
  si: [0, 23493871, 3462142, 5213, 998832, 11032225, 0],
  sk: [0, 34268351, 20143297, 20, 7130, 8398, 153140],
  sl: [0, 2348748, 11964, 6219, 3534, 260587, 0],
  sn: [1271, 26781750, 218104, 640548, 88020, 21189867, 367949],
  so: [0, 1754476, 246, 0, 9879, 367158, 0],
  sr: [0, 1068163, 27778, 0, 19532, 15585, 578],
  ss: [0, 19244, 29035, 21299, 4262, 290600, 0],
  sv: [0, 807602, 160319, 9072, 283781, 420768, 255291],
  sx: [0, 554494, 0, 945, 2252, 602, 0],
  sy: [0, 1007457, 913, 0, 1620, 2529735, 242661],
  sz: [0, 718219, 1242, 65260, 0, 138004, 0],
  tc: [0, 21167, 1086, 13106, 8395, 0, 0],
  td: [0, 1637061, 39, 0, 318, 24663, 0],
  tf: [0, 442, 455, 0, 0, 0, 0],
  tg: [45898163, 10409068, 6166, 77850, 199086, 52175551, 55823812],
  tj: [0, 430572, 26640, 0, 0, 246734, 1562453],
  tl: [0, 307141, 0, 2, 10025, 120794, 5150629],
  tm: [0, 6431443, 32642, 39159, 10, 577005, 0],
  tn: [40, 9275286, 5730670, 298646, 2856536, 2177599, 186477],
  to: [3044964, 1707455, 1704, 68421, 1468, 3045194, 601],
  tr: [78202, 84486570, 82158490, 6299852, 45071653, 143289315, 13680853],
  tt: [0, 3416919, 362938, 415778, 707882, 201507, 18866],
  tv: [0, 7, 0, 0, 0, 0, 146803],
  tz: [4040, 102144716, 911621, 5711753, 3950019, 19817116, 10288696],
  ua: [3, 7167407, 842668, 62371, 523721, 2501107, 722734],
  ug: [0, 5641969, 460516, 2670, 300120, 5668482, 50832],
  us: [63879019, 6886194480, 3992216589, 773938733, 129601270, 3510498366, 1602268814],
  uy: [0, 6561422, 39243, 0, 116662, 6017873, 694287],
  uz: [0, 9255299, 2782003, 0, 559583, 30314, 1350],
  va: [0, 8314, 65668, 0, 0, 0, 0],
  vc: [0, 506636, 15, 0, 7, 1676, 0],
  ve: [0, 11734096, 105271, 0, 782344, 3172797, 504843],
  vg: [0, 275708, 5, 0, 13661, 0, 0],
  vn: [120362464, 2801523982, 1256017594, 117997074, 315953945, 3994601884, 20186567974],
  vu: [6783748, 4209155, 104466, 18873, 3599, 6896990, 2018557],
  wf: [483240, 187111, 9024, 14159, 19139, 483240, 1439],
  ws: [10765152, 5398573, 1004, 43413, 6476, 10874716, 213090],
  ye: [3898346, 2770634, 28043, 5211870, 10220236, 4099660, 355422],
  yt: [227, 1251, 196, 1929, 1066, 227, 0],
  za: [11203, 1013292050, 259760348, 8349411, 44526879, 649102722, 519536388],
  zm: [7200, 1495193, 5718348, 140978, 458589, 155431, 0],
  zw: [0, 1154402, 33845, 9362, 24300, 265323, 17465],
}

const total = [6231482181, 54277929974, 19435899293, 3653395442, 3994850454, 57314117694, 204704647997]

const productNames = [
  "ซีเมนต์",
  "เหล็กและเหล็กกล้า",
  "อลูมิเนียม",
  "เซรามิค",
  "แก้ว",
  "ปิโตรเคมี",
  "ปิโตรเลียม",
]

const Ab1324 = () => {
  
  const [product, setProduct] = React.useState("ซีเมนต์")
  const [productIndex, setProductIndex] = React.useState(0)
  const handleChange = (e) => {
    setProduct(e.target.value);
    setProductIndex(productNames.indexOf(e.target.value));
  };

  return (
    <div>
      <div style={{maxWidth: '200px', margin: '2rem auto'}}>
        <FormControl fullWidth>
          <InputLabel>สินค้า</InputLabel>
          <Select
            value={product}
            onChange={handleChange}
            label="สินค้า"
            size="small"
          >
            {Object.values(productNames).map((p) => (
              <MenuItem key={p} value={p}>{p}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <LazyHighcharts
        constructorType={"mapChart"}
        options={
          {
            chart: {
              map: worldMap,
              // height: 600,
              spacingBottom: 20,
              type: "map",
            },
          
            dataLabels: {
              enabled: false,
            },
          
            title: {
              text: "",
            },
          
            xAxis: {
              visible: false,
            },
            yAxis: {
              visible: false,
            },
          
            mapView: {
              projection: {
                name: "Miller",
              },
            },
          
            legend: {
              enabled: true,
            },

            legend: {
              title: {
                text: 'สัดส่วนการส่งออก (ร้อยละ)',
                style: {
                  fontFamily: 'Dindan',
                },
              }
            },
          
            plotOptions: {
              map: {
                allAreas: false,
                tooltip: {
                  headerFormat: "",
                  // format to two decimals
                  pointFormat: "{point.name}: <b>{point.value:.2f}%</b> ({point.usd:,.0f} USD)",
                },
              },
            },
          
            colorAxis: {
              // type: "logarithmic",
              stops: [
                [0, 'white'],
                // [0, '#3060cf'],
                // [0.5, '#fffbbc'],
                [1, defaultStyles.colors[0]],
                // [1, '#c4463a'],
              ],
            },
          
            series: [
              {
                name: productNames[product],
                keys: ['hc-key', 'value', 'usd'],
                data: Object.keys(rawData)
                        .filter(country => rawData[country][productIndex] > 0)
                        .map((country) => [country, rawData[country][productIndex] / total[productIndex] * 100, rawData[country][productIndex]]),
                allAreas: true,
              },
            ],
          }
        }
      />
    </div>
  )
}

export default Ab1324